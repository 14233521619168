import {ICommonSaveActionType, ICommonSearchActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {MailingListModel} from '../../types/models/MailingListModel';
import {ISearchRequest, ISearchInRequest} from '../../types/common/SearchRequest';
import {MailingListSearchParamsModel} from '../../types/requests/MailingListSearchParamsModel';
import {RecipientSearchParamsModel} from '../../types/requests/RecipientSearchparamsModel';
import {MailingListSearchPaged} from '../../types/views/MailingListSearchPaged';
import {FileSearchParams} from '../../types/requests/FileSearchParams';
import {FILES} from '../files/Actions';
import {IPagedResult} from '../../types/common/PagedResult';
import {STUDENT_SEARCH} from '../students/Actions';
import {IMailingListRecipientModel} from '../../types/models/MailingListRecipientModel';
import {MailingListMessageSearchParamsModel} from '../../types/requests/MailingListMessageSearchParamsModel';
import {IMailingListMessageModel, MailingListMessageModel} from '../../types/models/MailingListMessageModel';

interface IMailingListActionType extends ICommonSaveActionType {
  DISABLE: string;
  DISABLED: string;
  DISABLE_ERROR: string;
  ENABLE: string;
  ENABLED: string;
  ENABLE_ERROR: string;
  REFRESH: string;
}

interface IMailingListSearchActionType extends ICommonSearchActionType {
  QUICK_SEARCH: string;
}


export const MAILING_LIST: IMailingListActionType = {
  DISABLE: 'mailingList/disable',
  DISABLED: 'mailingList/disableSuccess',
  DISABLE_ERROR: 'mailingList/disableError',
  ENABLE: 'mailingList/enable',
  ENABLED: 'mailingList/enableSuccess',
  ENABLE_ERROR: 'mailingList/enableError',
  LOAD: 'mailingList/load',
  LOADED: 'mailingList/loadSuccess',
  LOAD_ERROR: 'mailingList/loadError',
  SAVE: 'mailingList/save',
  SAVED: 'mailingList/saveSuccess',
  SAVE_ERROR: 'mailingList/saveError',
  REFRESH: 'mailingList/refresh',
};

export const MAILING_LIST_SEARCH: IMailingListSearchActionType = {
  CHANGE_VIEW: 'mailingListSearch/changeView',
  QUICK_SEARCH: 'mailingListSearch/quickSearch',
  ERROR: 'mailingListSearch/error',
  LOAD: 'mailingListSearch/load',
  LOADED: 'mailingListSearch/loadSuccess',
  REFRESH: 'mailingListSearch/refresh'
};

export const MAILING_LIST_MESSAGE: ICommonSaveActionType = {
  LOAD: 'mailingListMessage/load',
  LOADED: 'mailingListMessage/loadSuccess',
  LOAD_ERROR: 'mailingListMessage/loadError',
  SAVE: 'mailingListMessage/save',
  SAVED: 'mailingListMessage/saveSuccess',
  SAVE_ERROR: 'mailingListMessage/saveError'
};

export const MAILING_LIST_MESSAGE_SEARCH: ICommonSearchActionType = {
  CHANGE_VIEW: 'mailingListMessageSearch/changeView',
  ERROR: 'mailingListMessageSearch/error',
  LOAD: 'mailingListMessageSearch/load',
  LOADED: 'mailingListMessageSearch/loadSuccess',
  REFRESH: 'mailingListMessageSearch/refresh'
};

export const MAILING_LIST_RECIPIENT: ICommonSaveActionType = {
  LOAD: 'mailingListRecipient/load',
  LOADED: 'mailingListRecipient/loadSuccess',
  LOAD_ERROR: 'mailingListRecipient/loadError',
  SAVE: 'mailingListRecipient/save',
  SAVED: 'mailingListRecipient/saveSuccess',
  SAVE_ERROR: 'mailingListRecipient/saveError'
};

export const MAILING_LIST_RECIPIENT_SEARCH: ICommonSearchActionType = {
  CHANGE_VIEW: 'mailingListRecipientSearch/changeView',
  ERROR: 'mailingListRecipientSearch/error',
  LOAD: 'mailingListRecipientSearch/load',
  LOADED: 'mailingListRecipientSearch/loadSuccess',
  REFRESH: 'mailingListRecipientSearch/refresh'
};

export const mailingListLoad = createAction<string>(MAILING_LIST.LOAD);
export const mailingListLoadSuccess = createAction<MailingListModel>(MAILING_LIST.LOADED);
export const mailingListLoadError = createAction<string>(MAILING_LIST.LOAD_ERROR);
export const mailingListRefresh = createAction<string>(MAILING_LIST.REFRESH);
export const mailingListSave = createAction<string>(MAILING_LIST.SAVE);
export const mailingListSaveSuccess = createAction<string>(MAILING_LIST.SAVED);
export const mailingListSaveError = createAction<string>(MAILING_LIST.SAVE_ERROR);
export const mailingListEnable = createAction<string>(MAILING_LIST.ENABLE);
export const mailingListEnableSuccess = createAction<string>(MAILING_LIST.ENABLED);
export const mailingListEnableError = createAction<string>(MAILING_LIST.ENABLE_ERROR);
export const mailingListDisable = createAction<string>(MAILING_LIST.DISABLE);
export const mailingListDisableSuccess = createAction<string>(MAILING_LIST.DISABLED);
export const mailingListDisableError = createAction<string>(MAILING_LIST.DISABLE_ERROR);
export const mailingListSearch = createAction<ISearchRequest<MailingListSearchParamsModel>>(MAILING_LIST_SEARCH.LOAD);
export const mailingListSearchView = createAction<string>(MAILING_LIST_SEARCH.CHANGE_VIEW);
// Searches without persisting filters
export const mailingListQuickSearch = createAction<ISearchRequest<MailingListSearchParamsModel>>(MAILING_LIST_SEARCH.QUICK_SEARCH);
export const mailingListSearchSuccess = createAction<MailingListSearchPaged>(MAILING_LIST_SEARCH.LOADED);
export const mailingListSearchError = createAction<string>(MAILING_LIST_SEARCH.ERROR);

export const mailingListMessageLoad = createAction<string>(MAILING_LIST_MESSAGE.LOAD);
export const mailingListMessageLoadSuccess = createAction<MailingListMessageModel>(MAILING_LIST_MESSAGE.LOADED);
export const mailingListMessageLoadError = createAction<string>(MAILING_LIST_MESSAGE.LOAD_ERROR);
export const mailingListMessageSave = createAction<string>(MAILING_LIST_MESSAGE.SAVE);
export const mailingListMessageSaveSuccess = createAction<string>(MAILING_LIST_MESSAGE.SAVED);
export const mailingListMessageSaveError = createAction<string>(MAILING_LIST_MESSAGE.SAVE_ERROR);
export const mailingListMessageSearch = createAction<ISearchInRequest<MailingListMessageSearchParamsModel>>(MAILING_LIST_MESSAGE_SEARCH.LOAD);
export const mailingListMessageSearchSuccess = createAction<IPagedResult<IMailingListMessageModel>>(MAILING_LIST_MESSAGE_SEARCH.LOADED);
export const mailingListMessageSearchError = createAction<string>(MAILING_LIST_MESSAGE_SEARCH.ERROR);

export const mailingListRecipientLoad = createAction<string>(MAILING_LIST_RECIPIENT.LOAD);
export const mailingListRecipientLoadSuccess = createAction<IPagedResult<IMailingListRecipientModel>>(MAILING_LIST_RECIPIENT.LOADED);
export const mailingListRecipientLoadError = createAction<string>(MAILING_LIST_RECIPIENT.LOAD_ERROR);
export const mailingListRecipientSave = createAction<string>(MAILING_LIST_RECIPIENT.SAVE);
export const mailingListRecipientSaveSuccess = createAction<string>(MAILING_LIST_RECIPIENT.SAVED);
export const mailingListRecipientSaveError = createAction<string>(MAILING_LIST_RECIPIENT.SAVE_ERROR);

export const mailingListRecipientSearch = createAction<ISearchInRequest<RecipientSearchParamsModel>>(MAILING_LIST_RECIPIENT_SEARCH.LOAD);
export const mailingListRecipientSearchLoadSuccess = createAction<IPagedResult<IMailingListRecipientModel>>(MAILING_LIST_RECIPIENT_SEARCH.LOADED);
export const mailingListRecipientRefresh = createAction<ISearchInRequest<RecipientSearchParamsModel>>(MAILING_LIST_RECIPIENT_SEARCH.REFRESH);
export const mailingListRecipientSearchView = createAction<string>(MAILING_LIST_RECIPIENT_SEARCH.CHANGE_VIEW);