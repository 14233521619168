export class FormDraftIdentifier implements IFormDraftIdentifier {
  personId?: string | undefined;
  contextId?: string | undefined;
  contextType?: string | undefined;
  formDraftAdditionalIdentifier?: string | undefined;

  constructor(data?: IFormDraftIdentifier) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.personId = Data["personId"];
      this.contextId = Data["contextId"];
      this.contextType = Data["contextType"];
      this.formDraftAdditionalIdentifier = Data["formDraftAdditionalIdentifier"];
    }
  }

  static fromJS(data: any): FormDraftIdentifier {
    data = typeof data === 'object' ? data : {};
    let result = new FormDraftIdentifier();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["personId"] = this.personId;
    data["contextId"] = this.contextId;
    data["contextType"] = this.contextType;
    data["formDraftAdditionalIdentifier"] = this.formDraftAdditionalIdentifier;
    return data;
  }
}

export interface IFormDraftIdentifier {
  personId?: string | undefined;
  contextId?: string | undefined;
  contextType?: string | undefined;
  formDraftAdditionalIdentifier?: string | undefined;
}