export class FormDraftsModel implements IFormDraftsModel {
  personId?: string | undefined;
  contextId?: string | undefined;
  contextType?: string | undefined;
  eventRef?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  fields?: any | undefined;
  isCompleted?: boolean | undefined;
  dateCompleted?: Date | undefined;
  isIgnored?: boolean | undefined;
  dateIgnored?: Date | undefined;
  formDraftAdditionalIdentifier?: string | undefined;

  constructor(data?: IFormDraftsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.personId = Data["personId"];
      this.contextId = Data["contextId"];
      this.contextType = Data["contextType"];
      this.eventRef = Data["eventRef"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.fields = Data["fields"];
      this.isCompleted = Data["isCompleted"];
      this.dateCompleted = Data["dateCompleted"] ? new Date(Data["dateCompleted"].toString()) : <any>undefined;
      this.isIgnored = Data["isIgnored"];
      this.dateIgnored = Data["dateIgnored"] ? new Date(Data["dateIgnored"].toString()) : <any>undefined;
      this.formDraftAdditionalIdentifier = Data["formDraftAdditionalIdentifier"];
    }
  }

  static fromJS(data: any): FormDraftsModel {
    data = typeof data === 'object' ? data : {};
    let result = new FormDraftsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["personId"] = this.personId;
    data["contextId"] = this.contextId;
    data["contextType"] = this.contextType;
    data["eventRef"] = this.eventRef;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["fields"] = this.fields;
    data["isCompleted"] = this.isCompleted;
    data["dateCompleted"] = this.dateCompleted ? this.dateCompleted.toISOString() : <any>undefined;
    data["isIgnored"] = this.isIgnored;
    data["dateIgnored"] = this.dateIgnored ? this.dateIgnored.toISOString() : <any>undefined;
    data["formDraftAdditionalIdentifier"] = this.formDraftAdditionalIdentifier;
    return data;
  }
}

export interface IFormDraftsModel {
  personId?: string | undefined;
  contextId?: string | undefined;
  contextType?: string | undefined;
  eventRef?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  fields?: any | undefined;
  isCompleted?: boolean | undefined;
  dateCompleted?: Date | undefined;
  isIgnored?: boolean | undefined;
  dateIgnored?: Date | undefined;
  formDraftAdditionalIdentifier?: string | undefined;
}